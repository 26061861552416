.editor-menubar {
  button {
    border: 1px solid black;
    margin:4px;
    padding:4px;
  }
  .is-active {
    background-color: red;
  }
  :disabled {
    background-color: gray;
  }
}