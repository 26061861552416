.editor {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(203 213 225);
  border-radius: 0.375rem;
  padding: 1rem;
}
.ProseMirror {
  border: 1px solid rgb(203 213 225);
  border-radius: 0.375rem;
  padding: 20px;

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
  table {
    td,th {
      box-sizing: border-box;
      min-width: 1em;
      position: relative;
    }
    .selectedCell {
      :after {
        background: rgba(200, 200, 255, 0.4);
        content: "";
        left: 1px;
        right: 1px;
        top:  1px;
        bottom:  1px;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }
  }
}
.tableWrapper {
  overflow-x: auto;
}
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

@import './document.scss'