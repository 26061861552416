.editor-document {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding: 1rem;
  font-family: 'Pretendard', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1E293B;
  h1 {
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 700;
    strong {
      font-weight: 700
    }
  }
  h2 {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem; /* 32px */
    font-weight: 700;
    strong {
      font-weight: 700
    }
  }
  h3 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
    font-weight: 700;
    strong {
      font-weight: 700
    }
  }
  p {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
    font-weight: 400;
  }
  a {
    text-decoration: underline;
    color: rgb(2 132 199);
    :visited {
      color: rgb(2 132 199);
    }
  }
  ul {
    margin: 0;
    padding: 0;
    padding-inline-start: 20px;
    list-style-type: disc;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square;
      }
    }
  }
  ol {
    margin: 0;
    padding: 0;
    padding-inline-start: 20px;
    list-style-type: decimal;
    ol {
      list-style-type: upper-alpha;
      ol {
        list-style-type:lower-alpha;
      }
    }
  }
  li {
    padding-top:0.25rem;
    padding-bottom:0.25rem;
    p {
      display: inline-block;
    }
  }
  li::before {
    margin-inline-end: 10ch;
  }
  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;

    thead {
      background-color: rgb(249 250 251);
    }
    th, td {
      padding: 0.875rem;
      border-width: 1px;
      border-color: rgb(229 231 235);
      text-align: left;
    }
    th {
      background-color: rgb(249 250 251);
    }
  }

  // legacy document support
  article.main-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .poster {
      text-align: center;
      img { width: 100%; }
    }

    section.content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .tool-box {
      h2 {
        padding-top: 16px;
        border-top: 1px solid #000000;
      }
      > ul {
        display: flex;
        flex-direction: row;
        align-content: space-evenly;
        justify-content: flex-start;
        align-items: center;
        justify-items: center;
        border-bottom: 1px solid #000000;
        padding-left: 0;
        padding-bottom: 16px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        gap: 1rem;
        li {
          padding: 0;
          list-style-type: none;
          a {
            display: block;
            box-sizing: content-box;
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: top center;
          }
          .homepage {
              background: url('https://scan.dagada.co.kr/assets/images/homepage.svg');
          }
          .youtube {
            background: url('https://scan.dagada.co.kr/assets/images/youtube.svg');
          }
          .instagram {
            background: url('https://scan.dagada.co.kr/assets/images/instagram.svg');
          }
          .facebook {
            background: url('https://scan.dagada.co.kr/assets/images/facebook.svg');
          }
          .naver-blog {
            background: url('https://scan.dagada.co.kr/assets/images/naver-blog.svg');
          }
          .kakao-story {
            background: url('https://scan.dagada.co.kr/assets/images/kakao-story.svg');
          }
          .kakao-channel {
            background: url('https://scan.dagada.co.kr/assets/images/kakao-channel.svg');
          }
          :before {
            content: '';
          }
        }
      }
    }
    .list {
      list-style: none;
      padding-left: 0;
      li {
        background: #F1F1F0;
        border-radius: 8px;
        margin: 0 0 10px 0;
        line-height: 22px;
        a {
          display: block;
          padding: 16px 16px 16px 42px;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          background: url('https://scan.dagada.co.kr/assets/images/link.svg') no-repeat 16px 50%;
          text-decoration: none;
        }
      }
      .download {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 8px;
        margin: 0 0 10px 0;
        line-height: 22px;
        a {
          display: block;
          padding: 16px 16px 16px 48px;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
          background: url('https://scan.dagada.co.kr/assets/images/download.svg') no-repeat 12px 50%;
        }
      }
    }
    .sub-list {
      li {
        line-height: 24px;
        font-weight: 300;
        color: #292929;
      }
      :before {
        content: '· ';
      }
    }
  }
}